import {
    ARCHIVED_CANVASES_COUNT_SET,
    DASHBOARD_CANVAS_FILTER_TYPE_SET,
    DASHBOARD_EXPANDED_FOLDER_ID_ADD,
    DASHBOARD_EXPANDED_FOLDER_ID_REMOVE,
    DASHBOARD_FILTERED_CANVASES_SET,
    DASHBOARD_FOLDERS_NESTED_LIST_SET,
    DASHBOARD_SEARCH_TERM_SET,
    DASHBOARD_SELECTED_FOLDER_SET,
    DASHBOARD_SIDEBAR_WIDTH_SET,
    DASHBOARD_TEAM_INVITE_SET,
    DASHBOARD_VISITED_FOLDER_ID_ADD,
    FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_DELETE,
    FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_SET,
    MODAL_CANVAS_ENTER_CREDENTIALS_HIDE,
    MODAL_CANVAS_ENTER_CREDENTIALS_SHOW,
    MODAL_CANVAS_EXPIRY_HIDE,
    MODAL_CANVAS_EXPIRY_SHOW,
    MODAL_CANVAS_REMINDERS_HIDE,
    MODAL_CANVAS_REMINDERS_SHOW,
    MODAL_CREATE_EMAIL_CANVAS_HIDE,
    MODAL_CREATE_EMAIL_CANVAS_SHOW,
    MODAL_CREATE_WEBSITE_CANVAS_HIDE,
    MODAL_CREATE_WEBSITE_CANVAS_SHOW,
    MODAL_DELETE_FOLDER_HIDE,
    MODAL_DELETE_FOLDER_SHOW,
    MODAL_DESIGN_CANVAS_UPLOAD_HIDE,
    MODAL_DESIGN_CANVAS_UPLOAD_SHOW,
    MODAL_EDIT_CANVAS_HIDE,
    MODAL_EDIT_CANVAS_SHOW,
    MODAL_EDIT_FOLDER_HIDE,
    MODAL_EDIT_FOLDER_SHOW,
    MODAL_MOVE_CANVAS_TO_FOLDER_HIDE,
    MODAL_MOVE_CANVAS_TO_FOLDER_SHOW,
    MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_HIDE,
    MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_SHOW,
    MODAL_SET_FEEDBACK_DEADLINE_HIDE,
    MODAL_SET_FEEDBACK_DEADLINE_SHOW,
    MODAL_SHARE_FOLDER_HIDE,
    MODAL_SHARE_FOLDER_SHOW,
    MODAL_SWITCH_CANVAS_TO_PREMIUM_HIDE,
    MODAL_SWITCH_CANVAS_TO_PREMIUM_SHOW,
    MODAL_TEAM_INVITE_HIDE,
    MODAL_TEAM_INVITE_SHOW,
    MODAL_TRIAL_EXPIRED_HIDE,
    MODAL_TRIAL_EXPIRED_SHOW
} from './types';

const initialState = {
    archivedCanvasesCount: 0,
    canvasFilterType: 'all',
    createDesignCanvasModalAssetType: null,
    createDesignCanvasModalOpen: false,
    createEmailCanvasModalOpen: false,
    createWebsiteCanvasModalOpen: false,
    deleteFolderModalFolderId: null,
    editModalCanvasId: null,
    editModalFolderId: null,
    enterCredentialsModalOpen: false,
    expandedFolderIds: [],
    expiryModalCanvasId: null,
    feedbackDeadlineModalCanvasId: null,
    feedbackDeadlineModalOpenLocation: null /* Can be 'share-modal' or '' */,
    filteredCanvasIds: [],
    foldersNestedList: [],
    initialSidebarWidth: 233,
    inviteId: null,
    moveFolderModalCanvasId: null,
    remindersCanvasId: null,
    search: '',
    selectCanvasDeadlineRemindersModalOpen: false,
    selectedFolderId: null,
    shareFolderModalOpen: false,
    switchToPremiumModalCanvasId: null,
    teamInviteModalOpen: false,
    trialExpiredModalOpen: false,
    urlNeedingCredentials: null,
    visitedFolderIds: []
};

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case ARCHIVED_CANVASES_COUNT_SET: {
            return { ...state, archivedCanvasesCount: action.payload.canvasesCount };
        }
        case DASHBOARD_CANVAS_FILTER_TYPE_SET: {
            return { ...state, canvasFilterType: action.payload.canvasFilterType };
        }
        case DASHBOARD_FILTERED_CANVASES_SET: {
            return { ...state, filteredCanvasIds: action.payload.canvasIds };
        }
        case DASHBOARD_FOLDERS_NESTED_LIST_SET: {
            return { ...state, foldersNestedList: action.payload.list };
        }
        case DASHBOARD_EXPANDED_FOLDER_ID_ADD: {
            return { ...state, expandedFolderIds: [...state.expandedFolderIds, action.payload.id] };
        }
        case DASHBOARD_EXPANDED_FOLDER_ID_REMOVE: {
            return { ...state, expandedFolderIds: state.expandedFolderIds.filter((id) => id !== action.payload.id) };
        }
        case DASHBOARD_SEARCH_TERM_SET: {
            return { ...state, search: action.payload.search };
        }
        case DASHBOARD_SELECTED_FOLDER_SET: {
            return { ...state, selectedFolderId: action.payload.folderId };
        }
        case DASHBOARD_SIDEBAR_WIDTH_SET: {
            return { ...state, initialSidebarWidth: action.payload.width };
        }
        case DASHBOARD_TEAM_INVITE_SET: {
            return { ...state, inviteId: action.payload.inviteId };
        }
        case DASHBOARD_VISITED_FOLDER_ID_ADD: {
            return { ...state, visitedFolderIds: [...new Set([...state.visitedFolderIds, action.payload.folderId])] };
        }
        case FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_SET: {
            return { ...state, feedbackDeadlineModalOpenLocation: action.payload.location };
        }
        case FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_DELETE: {
            return { ...state, feedbackDeadlineModalOpenLocation: null };
        }
        case MODAL_CANVAS_EXPIRY_SHOW: {
            return { ...state, expiryModalCanvasId: action.payload.canvasId };
        }
        case MODAL_CANVAS_EXPIRY_HIDE: {
            return { ...state, expiryModalCanvasId: null };
        }
        case MODAL_EDIT_CANVAS_SHOW: {
            return { ...state, editModalCanvasId: action.payload.canvasId };
        }
        case MODAL_EDIT_CANVAS_HIDE: {
            return { ...state, editModalCanvasId: null };
        }
        case MODAL_EDIT_FOLDER_SHOW: {
            return { ...state, editModalFolderId: action.payload.folderId };
        }
        case MODAL_EDIT_FOLDER_HIDE: {
            return { ...state, editModalFolderId: null };
        }
        case MODAL_CANVAS_ENTER_CREDENTIALS_SHOW: {
            return {
                ...state,
                enterCredentialsModalOpen: true,
                urlNeedingCredentials: action.payload.url
            };
        }
        case MODAL_CANVAS_ENTER_CREDENTIALS_HIDE: {
            return { ...state, enterCredentialsModalOpen: false, urlNeedingCredentials: null };
        }
        case MODAL_CANVAS_REMINDERS_SHOW: {
            return { ...state, remindersCanvasId: action.payload.canvasId };
        }
        case MODAL_CANVAS_REMINDERS_HIDE: {
            return { ...state, remindersCanvasId: null };
        }
        case MODAL_CREATE_EMAIL_CANVAS_SHOW: {
            return { ...state, createEmailCanvasModalOpen: true };
        }
        case MODAL_CREATE_EMAIL_CANVAS_HIDE: {
            return { ...state, createEmailCanvasModalOpen: false };
        }
        case MODAL_CREATE_WEBSITE_CANVAS_SHOW: {
            return { ...state, createWebsiteCanvasModalOpen: true };
        }
        case MODAL_CREATE_WEBSITE_CANVAS_HIDE: {
            return { ...state, createWebsiteCanvasModalOpen: false };
        }
        case MODAL_DELETE_FOLDER_SHOW: {
            return { ...state, deleteFolderModalFolderId: action.payload.folderId };
        }
        case MODAL_DELETE_FOLDER_HIDE: {
            return { ...state, deleteFolderModalFolderId: null };
        }
        case MODAL_DESIGN_CANVAS_UPLOAD_SHOW: {
            return {
                ...state,
                createDesignCanvasModalOpen: true,
                createDesignCanvasModalAssetType: action.payload.assetType
            };
        }
        case MODAL_DESIGN_CANVAS_UPLOAD_HIDE: {
            return { ...state, createDesignCanvasModalOpen: false, createDesignCanvasModalAssetType: null };
        }
        case MODAL_MOVE_CANVAS_TO_FOLDER_SHOW: {
            return { ...state, moveFolderModalCanvasId: action.payload.canvasId };
        }
        case MODAL_MOVE_CANVAS_TO_FOLDER_HIDE: {
            return { ...state, moveFolderModalCanvasId: null };
        }
        case MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_SHOW: {
            return { ...state, selectCanvasDeadlineRemindersModalOpen: true };
        }
        case MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_HIDE: {
            return { ...state, selectCanvasDeadlineRemindersModalOpen: false };
        }
        case MODAL_SET_FEEDBACK_DEADLINE_SHOW: {
            return { ...state, feedbackDeadlineModalCanvasId: action.payload.canvasId };
        }
        case MODAL_SET_FEEDBACK_DEADLINE_HIDE: {
            return { ...state, feedbackDeadlineModalCanvasId: null };
        }
        case MODAL_SHARE_FOLDER_SHOW: {
            return { ...state, shareFolderModalOpen: true };
        }
        case MODAL_SHARE_FOLDER_HIDE: {
            return { ...state, shareFolderModalOpen: false };
        }
        case MODAL_SWITCH_CANVAS_TO_PREMIUM_SHOW: {
            return { ...state, switchToPremiumModalCanvasId: action.payload.canvasId };
        }
        case MODAL_SWITCH_CANVAS_TO_PREMIUM_HIDE: {
            return { ...state, switchToPremiumModalCanvasId: null };
        }
        case MODAL_TEAM_INVITE_HIDE: {
            return { ...state, teamInviteModalOpen: false };
        }
        case MODAL_TEAM_INVITE_SHOW: {
            return { ...state, teamInviteModalOpen: true };
        }
        case MODAL_TRIAL_EXPIRED_SHOW: {
            return { ...state, trialExpiredModalOpen: true };
        }
        case MODAL_TRIAL_EXPIRED_HIDE: {
            return { ...state, trialExpiredModalOpen: false };
        }
        default:
            return state;
    }
};

export default dashboard;
